<template>
  <div class="myopiaHazard">
    <h3 class="title">高度近视</h3>
    <div class="container">
      <div class="left">
        <div class="typeItem" v-for="item in list">
          <p class="prefix">{{item.prefix}}</p>
          <div class="suffix">
            <p v-for="item1 in item.suffix">{{item1}}</p>
          </div>
        </div>
      </div>
      <div class="right">
        <video style="width: 80%" controls autoplay="autoplay" loop="loop">
          <source src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/video/high_myopia.mp4" type="video/mp4">
          <source src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/video/high_myopia.mp4" type="video/ogg">
          您的浏览器不支持Video标签。
        </video>
      </div>
    </div>
    <div class="btnGroup" >
      <button class="retBtn" @click="go" style="display: none">返回</button>
      <button class="myopiaBtn" @click="myopiaClick">高度近视并发症</button>
    </div>

    <div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myopiaHazard",
  data() {
    return {
      list: [],
      flag : -1
    }
  },
  methods: {
    go() {
      this.$router.go(-1);
    },
    myopiaClick(){
      this.$router.push({
        path:'/PopularScienceHome/myopiaComplication'
      });
    }
  },
  created() {
    let arr =[
      {
        prefix: '定义：',
        suffix: [
          '近视超过-6.00度就位高度近视'
        ]
      },
      {
        prefix: '临床症状：',
        suffix: [
          '远视力下降，斜视，视疲劳，眼球突出，瞳孔对光反应迟钝，豹纹状眼底',
        ]
      },
      {
        prefix: '并发症：',
        suffix: [
          '黄斑病变(黄斑萎缩、黄斑出血、黄斑裂空、视网膜脱离)，玻璃体液化(飞蚊症)，白内障，青光眼'
        ]
      },
      {
        prefix: '日常护理：',
        suffix: [
          '1.保持良好的心态，合理的饮食，适度的锻炼，避免过度用眼。',
          '2.对于青少年儿童，防控尤为最要，建议采取合理的屈光矫正方式，减少近距离用眼、眼保健方式以减少视疲劳。',
          '3.对于高度近视人群尽量避免做极限、高强度的剧烈运动，防止视网膜脱离；应避免高强度持续用眼。',
          '4.至少每半年到眼科做眼底检查、以争取早发现、早干预'
        ]
      }
    ];
    this.list = arr;
  }
}
</script>

<style scoped lang="scss">
.frameMirror {
  width: 100vw;
}
.title {
  margin: 15px;
  font-size: 18px;
  line-height: 18px;
}
.container {
  width: 100%;
  display: flex;
  align-items: center;
}
.left {
  flex: 1;
  width: 100%;
  padding-left: 10px;
}
.right {
  flex: 1;
}
.left p {
  text-align: left;
}
.typeItem {
  margin-top: 20px;
  overflow: hidden;
}
.typeItem .prefix {
  font-size: 16px;
  font-weight: 600;
}
.typeItem .suffix {
}
.typeItem .suffix p {
  font-size: 16px;
  text-indent: 1em;
}
.radioGroup {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radioGroup label {
  width: 100px;
  display: flex;
  align-items: center;
}
.radioGroup input {
  margin-right: 5px;
}
.scene img {
  width: 16px;
  height: 16px;
}
.btnGroup {
  display: flex;
  justify-content: center;
}
.btnGroup button {
  margin: 50px;
  padding: 10px 0;
  width: 210px;
  border: 1px solid #333;
  color: #333;
  background: transparent;
  font-size: 18px;
}

</style>
